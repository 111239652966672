import commonEffects from "./commonEffects";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { SplitText } from "gsap/SplitText";
var Sticky = require("sticky-js");

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(SplitText);

const servicesAnimations = {
  initLoad() {
    if ($(".services-hero").length == 0) return;

    this.hero();
    this.textContent();
    this.twoColumns();
  },
  // changeMenuColor(menuBackground, nextMenuColor) {
  //   const header = $(".header");
  //   gsap
  //     .timeline()
  //     .addLabel("zero")
  //     .to(header.find(".logo-wrapper svg g"), { fill: nextMenuColor }, "zero")
  //     .to(
  //       header.find(".hamburger__line"),
  //       { background: nextMenuColor },
  //       "zero"
  //     )
  //     .to(header.find(".hamburger"), { borderColor: nextMenuColor }, "zero");
  //   gsap.to(header, {
  //     backgroundColor: menuBackground,
  //     duration: 0.5,
  //   });
  // },
  hero() {
    // title
    const heroImage = $(".services-hero .c-hero__image");
    const heroTitle = $(".services-hero .c-hero__title");
    const heroTitleSplit = new SplitText(heroTitle, {
      type: "lines,words,chars",
      charsClass: "char",
      linesClass: "split-line",
    });

    const heroArrow = $(".services-hero .c-hero__link");

    gsap.set(heroArrow, { opacity: 0 });
    gsap
      .timeline()
      .delay(0.5)
      .addLabel("zero")
      .fromTo(
        heroImage,
        commonEffects.imagesZoom.set,
        commonEffects.imagesZoom.to,
        "zero-=.3"
      )
      .from(heroTitleSplit.chars, commonEffects.heroTitles, "zero")
      .to(
        heroArrow,
        {
          opacity: 1,
          transformOrigin: "center center",
          duration: 0.8,
          ease: "Power2.out",
        },
        "zero+=0.5"
      );
  },
  textContent() {
    // title
    const textContentTitle = $(".services-text-content .c-text-content__title");
    const textContentTitleSplit = new SplitText(textContentTitle, {
      type: "lines,words",
      linesClass: "split-line",
      wordsClass: "split-line__word",
    });

    const textContentDescription = $(
      ".services-text-content .c-text-content__description"
    );

    gsap.set(textContentDescription, commonEffects.descriptions.set);
    gsap
      .timeline({
        scrollTrigger: {
          trigger: textContentTitle,
          start: "top 75%",
          end: "bottom -20%",
          markers: false,
          toggleActions: "play reverse play reverse",
          onEnter: function () {
            // servicesAnimations.changeMenuColor("#e4e4e4", "rgb(17,17,17)");
          },
          onEnterBack: function () {
            // servicesAnimations.changeMenuColor("#e4e4e4", "rgb(17,17,17)");
          },
        },
      })
      .addLabel("zero")
      .from(textContentTitleSplit.words, commonEffects.titles, "zero-=.3")
      .to(textContentDescription, commonEffects.descriptions.to, "zero+=0.3");
  },
  twoColumns() {
    $(".c-two-columns").each(function (index) {
      if ($(window).outerWidth() < 991) {
        $(".c-two-columns .c-two-columns__wrapAll").removeClass("opened");
        servicesAnimations.columnsAnimMobile($(this));
      } else {
        servicesAnimations.columnsAnimDesktop($(this));
      }
    });

    $(".c-two-columns .c-two-columns__wrapAll .arrowLink-wrapper").on(
      "click",
      function () {
        let row = $(this).parent().parent().parent().parent().parent();
        let wrapAll = $(this).parent();

        wrapAll.toggleClass("opened");
        wrapAll.find('.c-two-columns__voices').css('opacity','0')
        if (row.find(".c-two-columns__wrapAll").length > 0) {

          servicesAnimations.setColumns(row);
        }
        setTimeout(() => {

        wrapAll.find('.c-two-columns__voices').css('opacity','1')
        }, 210);

        ScrollTrigger.update();
      }
    );

    // remove opened
    setTimeout(() => {
      $(".c-two-columns__wrapAll").removeClass("opened");
    }, 500);

    $(".c-two-columns .voice__voice-wrapper").on("click", function () {
      let clickedIndex = $(this).index();
      $(".c-two-columns .voice__voice-wrapper").each(function (arrayIndex) {
        if (clickedIndex !== arrayIndex) $(this).removeClass("opened");
      });
    });
  },
  columnsAnimMobile(row) {
    const twoColumnsTextContent = row.find(".c-two-columns__text-content");
    const twoColumnsTitle = row.find(".c-two_columns__title");
    const twoColumnsTitleSplit = new SplitText(twoColumnsTitle, {
      type: "lines,words,chars",
      linesClass: "split-line",
    });
    const twoColumnsDescription = row.find(".c-two_columns__description");
    const twoColumnsVoices = row.find(
      ".c-two-columns__voices .voice__voice-wrapper .text"
    );
    const twoColumnsImages = row.find(".c-two-columns__image-wrapper img");
    const twoColumnsWrapAll = row.find(".c-two-columns__wrapAll");

    // EFFECTS
    gsap.set(twoColumnsImages, commonEffects.images.set);
    gsap.set(twoColumnsDescription, commonEffects.descriptions.set);
    gsap.set(twoColumnsVoices, commonEffects.lists.set);
    gsap.set(twoColumnsWrapAll, commonEffects.descriptions.set);

    gsap
      .timeline({
        scrollTrigger: {
          trigger: twoColumnsImages,
          start: "top 75%",
          end: "bottom bottom",
          markers: false,
        },
      })
      .addLabel("zero")
      .to(twoColumnsImages, commonEffects.images.to, "zero");

    gsap
      .timeline({
        scrollTrigger: {
          trigger: twoColumnsTextContent,
          start: "top 75%",
          end: "+=500px",
          markers: false,
        },
      })
      .addLabel("zero")
      .from(twoColumnsTitleSplit.chars, commonEffects.titles, "zero")
      .to(twoColumnsDescription, commonEffects.descriptions.to, "zero+=.5")
      .to(
        twoColumnsVoices,
        {
          xPercent: 0,
          stagger: 0.03,
          ease: "power4.out",
        },
        "zero+=.5"
      )
      .to(twoColumnsWrapAll, commonEffects.descriptions.to, "zero+=.5");
  },
  columnsAnimDesktop(row) {
    const twoColumnsTitle = row.find(".c-two_columns__title");
    const twoColumnsTitleSplit = new SplitText(twoColumnsTitle, {
      type: "lines,words,chars",
      linesClass: "split-line",
    });
    const twoColumnsDescription = row.find(".c-two_columns__description");
    const twoColumnsVoices = row.find(
      ".c-two-columns__voices .voice__voice-wrapper .text"
    );
    const twoColumnsImages = row.find(".c-two-columns__image-wrapper img");
    const twoColumnsWrapAll = row.find(".c-two-columns__wrapAll");

    // EFFECTS
    gsap.set(twoColumnsImages, commonEffects.images.set);
    gsap.set(twoColumnsDescription, commonEffects.descriptions.set);
    gsap.set(twoColumnsVoices, commonEffects.lists.set);
    gsap.set(twoColumnsWrapAll, commonEffects.descriptions.set);
    gsap
      .timeline({
        scrollTrigger: {
          trigger: row,
          start: "top 70%",
          markers: false,
          // scrub: true,
          end: "+=500px",
          onEnter: () => console.log(1),
        },
      })
      .addLabel("zero")
      .from(twoColumnsTitleSplit.chars, commonEffects.titles, "zero")
      .to(twoColumnsImages, commonEffects.images.to, "zero")
      .to(twoColumnsDescription, commonEffects.descriptions.to, "zero+=.5")
      .to(
        twoColumnsVoices,
        {
          xPercent: 0,
          stagger: 0.03,
          ease: "power4.out",
        },
        "zero+=.5"
      )
      .to(twoColumnsWrapAll, commonEffects.descriptions.to, "zero+=.5");
  },
  setColumns(row) {

    if ($(window).outerWidth() < 991) return
    const twoColumnsTextContent = row.find(".c-two-columns__text-content ");
    const twoColumnsImages = row.find(".c-two-columns__image-wrapper img");

    // set child woth max height opened in order to calculate max height available
    let voices = row.find(".c-two-columns__voices .voice__voice-wrapper");
    let voicesIndex = [];
    voices.each(function (index) {
      voicesIndex[index] = row.children().length;
    });

    let maxChildren = Math.max.apply(Math, voicesIndex);
    // as it starts from 0
    let maxChildrenIndex = voicesIndex.indexOf(maxChildren) + 1;
    row
      .find(
        ".c-two-columns__voices .voice__voice-wrapper:nth-child(" +
          maxChildrenIndex +
          ")"
      )
      .addClass("opened");


        gsap.timeline({
          scrollTrigger: {
            trigger: row,
            start: "top " + $(".header").outerHeight(),
            end: "+=50%",
            pin: twoColumnsTextContent.find(
              ".c-two-columns__text-content__wrapper"
            ),
            pinSpacing: true,
          },
        });


        gsap.timeline({
          scrollTrigger: {
            trigger: row,
            start: "top " + $(".header").outerHeight(),
            end: "bottom bottom",
            pin: twoColumnsImages,
            onEnterBack: function () {
              // servicesAnimations.changeMenuColor("#e4e4e4", "rgb(17,17,17)");
            },
          },
        });


    setTimeout(() => {
      $(".voice__voice-wrapper").removeClass("opened");
    }, 100);
  },
};

module.exports = servicesAnimations;
