import commonEffects from "./commonEffects";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { SplitText } from "gsap/SplitText";

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(SplitText);

const methodAnimations = {
  initLoad() {
    if ($(".method-hero").length == 0) return;
    this.hero();
    this.textContent();
    this.certifications();
    this.steps();
  },
  hero() {
    // title
    const heroImage = $(".method-hero .c-hero__image");
    const heroTitle = $(".method-hero .c-hero__title");
    const heroTitleSplit = new SplitText(heroTitle, {
      type: "lines,words,chars",
      charsClass: "char",
      linesClass: "split-line",
    });

    const heroArrow = $(".method-hero .c-hero__link");

    gsap.set(heroArrow, { opacity: 0 });
    gsap
      .timeline()
      .delay(0.5)
      .fromTo(
        heroImage,
        commonEffects.imagesZoom.set,
        commonEffects.imagesZoom.to, "zero-=.3"
      )
      .from(heroTitleSplit.chars, commonEffects.heroTitles, "zero")
      .to(
        heroArrow,
        {
          opacity: 1,
          transformOrigin: "center center",
          duration: 0.8,
          ease: "Power2.out",
        },
        "zero+=0.5"
      );
  },
  certifications() {
    const certifications = $(
      ".method-text-content .method-text-content__certifications"
    );

    gsap
      .timeline({
        scrollTrigger: {
          trigger: certifications,
          start: "top 25%",
          end: "bottom -20%",
          markers: false,
          toggleActions: "play reverse play reverse",
        },
      })
      .addLabel("zero")
      .to(
        certifications.find(".certification"),
        { opacity: 0, stagger: 0.15 },
        "zero"
      );
  },
  textContent() {
    // title
    const textContentTitle = $(".method-text-content .c-text-content__title");
    const textContentTitleSplit = new SplitText(textContentTitle, {
      type: "lines,words",
      linesClass: "split-line",
      wordsClass: "split-line__word",
    });

    const textContentDescription = $(
      ".method-text-content .c-text-content__description"
    );

    gsap.set(textContentDescription, commonEffects.descriptions.set);

    gsap
      .timeline({
        scrollTrigger: {
          trigger: textContentTitle,
          start: "top 75%",
          end: "bottom -20%",
          markers: false,
          toggleActions: "play reverse play reverse",
        },
      })
      .addLabel("zero")
      .from(textContentTitleSplit.words, commonEffects.titles, "zero-=.3")
      .addLabel("one")
      .to(textContentDescription, commonEffects.descriptions.to, "zero+=0.3");
  },
  steps() {
    // let end = "bottom 70%"
    let end = "bottom center";
    let start = "top 75%";
    if ($(window).outerWidth() < 1200)
      (end = "bottom 90%"), (start = "top 60%");

    $(".method-steps .step-wrapper").each(function (index) {
      let textContentTitle = $(this).find(".title-wrapper .title");
      let textContentTitleSplit = new SplitText(textContentTitle, {
        type: "lines,words,chars",
        linesClass: "split-line",
      });
      let description = $(this).find(".text-wrapper .description");
      let indexWrapper = $(this).find(".index-wrapper");
      let contentWrapper = $(this).find(".content-wrapper");

      gsap.set(indexWrapper, commonEffects.descriptions.set);
      gsap.set(description, commonEffects.descriptions.set);
      gsap
        .timeline({
          scrollTrigger: {
            trigger: $(this),
            start: start,
            end: end,
            ease: "power4.out",
            toggleActions: "play stop play stop",
            markers: false,
          },
        })
        .addLabel("zero")
        .fromTo(
          contentWrapper,
          { clipPath: "polygon(0 0, 0 0, 0 100%, 0% 100%)" },
          { stagger: 0.1, clipPath: "polygon(100% 0, 0 0, 0 100%, 100% 100%)" },
          "zero-=.5"
        )
        .to(indexWrapper, commonEffects.descriptions.to, "zero")
        .from(textContentTitleSplit.chars, commonEffects.titles, "zero+=.1")
        .to(description, commonEffects.descriptions.to, "zero+=.2");

      if ($(window).outerWidth() < 991) return;

      gsap.timeline({
        scrollTrigger: {
          trigger: $(this),
          start: "top 15%",
          end: end,
          ease: "power4.out",
          pin: true,
          scrub: false,
          toggleActions: "play stop play stop",
        },
      });
    });
  },
};

module.exports = methodAnimations;
