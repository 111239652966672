const commonEffects = {
  // heroTitles: {
  //   duration: 1.4,
  //   opacity: 1,
  //   scale: 0,
  //   y: 80,
  //   rotationX: 180,
  //   transformOrigin: "0% 50% -50",
  //   ease: "back",
  //   stagger: 0.05
  // },
  heroTitles: {
    duration: 0.8,
    ease: "circ.easeOut",
    yPercent: 100,
    stagger: 0.02,
    opacity: 0,
  },
  titles: {
    duration: 0.6,
    ease: "circ.easeOut",
    // opacity:0,
    opacity:0.5,
    y: 80,
  },
  descriptions: {
    set: { opacity: 0, y: 20 },
    to: { duration: .8, ease: "circ.easeOut", opacity: 1, y: 0}
  },
  lists: {
    set: { xPercent: -200 },
    to: { xPercent: 0, stagger: .12, duration: 1, ease: "power4.out" }
  },
  slider: {
    set: { yPercent: 100 },
    to: {
      duration: 0.8,
      ease: "power4.out",
      yPercent: 0,
      stagger: 0.2,
      // delay: -.7
    }
  },
  images: {
    set: { "clip-path": "polygon(0% 0%, 0% 0%, 0% 100%, 0% 100%)", duration: .3, ease: "Expo.easeOut" },
    to: { opacity: 1, "clip-path": "polygon(0 0, 100% 0, 100% 100%, 0% 100%)", duration: .8, ease: "Expo.out", delay: .3 }
  },
  imagesZoom: {
    set: { scale: 1.25, ease: "Expo.easeOut" },
    to: { scale: 1, duration: .5, ease: "Expo.out" }
  },
}

export default commonEffects
