import $ from "jquery";
import "../sass/main.scss";
import menu from "./menu";
import lazyLoad from "./lazyLoad";
import smoothState from "./smoothState";
import maps from "./maps";
import slider from "./extra/slider";
import voicesList from "./extra/voicesList";
import video from "./extra/video";
import animations from "./extra/animations";
import scrollMethods from "./extra/scrollMethods";
import { ScrollTrigger } from "gsap/ScrollTrigger";

window.$ = require("jquery");
window.jQuery = require("jquery");

const siteGlobal = {
  initReady() {
    smoothState.initReady();
    $("#preloader").height($(window).outerHeight());
    animations.preloader();
    video.initReady();
  },
  initLoad() {
    $(window).scrollTop(0);
    lazyLoad.initLoad();
    siteGlobal.setFullHeight();
    maps.initLoad();
    menu.initLoad();
    animations.detectHeroScroll();
    animations.initLoad();
    slider.initLoad();
    voicesList.initLoad();
    scrollMethods.initLoad();

    // wait 1s as page load could trigger sections
    // before time and change header color incorrectly
    setTimeout(() => {
      console.log('waited 200ms');
      ScrollTrigger.refresh()
      menu.setHeaderColor();
      video.initReady();
      animations.backgroundChange();
    }, 500);
  },
  rebuildAllEvents() {
    siteGlobal.initLoad();
  },
  initScroll() {
    menu.initScroll();
    // animations.setHeroAnimable()
  },
  initResize() {},
  rebuild() {
    siteGlobal.rebuildAllEvents();
  },
  setFullHeight() {
    $(".c-hero,.menu-wrapper").height($(window).outerHeight());
  },
};

$(document).ready(() => {
  siteGlobal.initReady();
});

$(document).on("rebuild", () => {
  siteGlobal.rebuild();
});

$(window).on("load", () => {
  $("#preloader").addClass("loaded");
  siteGlobal.initLoad();
});

$(window).on("resize", () => {
  siteGlobal.initResize();
});

$(window).on("scroll", () => {
  siteGlobal.initScroll();
});
