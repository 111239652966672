import commonEffects from "./commonEffects";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { SplitText } from "gsap/SplitText";

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(SplitText);

const whoWeAreAnimations = {
  initLoad() {
    if ($(".who-we-are-hero ").length == 0) return;
    this.hero();
    this.textContent();
    this.team();
  },
  hero() {
    // title
    const heroImage = $(".who-we-are-hero .c-hero__image");
    const heroTitle = $(".who-we-are-hero .c-hero__title");
    const heroTitleSplit = new SplitText(heroTitle, {
      type: "lines,words,chars",
      charsClass: "char",
      linesClass: "split-line",
    });

    const heroArrow = $(".who-we-are-hero .c-hero__link");

    gsap.set(heroArrow, { opacity: 0 });
    gsap
      .timeline()
      .delay(.5)
      .addLabel("zero")
      .fromTo(heroImage, commonEffects.imagesZoom.set,  commonEffects.imagesZoom.to, "zero-=.3")
      .from(heroTitleSplit.chars, commonEffects.heroTitles, "zero")
      .to(
        heroArrow,
        {
          opacity: 1,
          transformOrigin: "center center",
          duration: 0.8,
          ease: "Power2.out",
        },
        "zero+=0.5"
      );
  },
  textContent() {
    // title
    const textContentTitle = $(
      ".who-we-are-text-content .c-text-content__title"
    );
    const textContentTitleSplit = new SplitText(textContentTitle, {
      type: "lines,words",
      linesClass: "split-line",
      wordsClass: "split-line__word",
    });

    const textContentDescription = $(
      ".who-we-are-text-content .c-text-content__description"
    );

    gsap.set(textContentDescription, commonEffects.descriptions.set);

    gsap
      .timeline({
        scrollTrigger: {
          trigger: textContentTitle,
          start: "center center",
          end: "+=250px",
          markers: false,
        },
      })
      .addLabel("zero")
      .from(textContentTitleSplit.words, commonEffects.titles, "zero-=.3")
      .to(textContentDescription, commonEffects.descriptions.to, "zero+=.3");
  },
  team() {
    // title
    const textContentTitle = $(
      ".who-we-are-team .who-we-are-team__wrapper-top .title"
    );
    const textContentTitleSplit = new SplitText(textContentTitle, {
      type: "lines,words,chars",
      linesClass: "split-line",
    });

    const textContentDescription = $(
      ".who-we-are-team .who-we-are-team__wrapper-top .description"
    );
    const teamSlider = $(".who-we-are-team__wrapper-group .member");

    if ($(window).outerWidth() < 767) {
      gsap.set(textContentDescription, commonEffects.descriptions.set);
      gsap.set(teamSlider, commonEffects.slider.set);
      gsap
        .timeline({
          scrollTrigger: {
            trigger: textContentTitle,
            start: "top 75%",
            end: "bottom -20%",
            markers: false,
            toggleActions: "play stop play reverse",
          },
        })
        .addLabel("zero")
        .from(textContentTitleSplit.chars, commonEffects.titles, "zero")
        .addLabel("one")
        .to(textContentDescription, commonEffects.descriptions.to, "-=0.4");

      let start = $(window).outerWidth() < 767 ? 'top 140%' :  'top 75%'
      teamSlider.each(function (index, slide) {
        gsap
          .timeline({
            onUpdate: function (self) {
              console.log(1);
              // avoid image translate errors (end of images is clipped without updating positions)
              // dont delete
              if ($(window).outerWidth() < 767) {
                ScrollTrigger.update();
              }
            },
            scrollTrigger: {
              trigger: slide,
              markers: false,
              start: start,
              toggleActions: "play stop play reverse",
            },
          })
          .to(slide, {
            duration: 0.8,
            ease: "power4.out",
            yPercent: 0,
          });
      });

      return;
    }

    gsap.set(textContentDescription, commonEffects.descriptions.set);
    gsap.set(teamSlider, commonEffects.slider.set);
    gsap
      .timeline({
        scrollTrigger: {
          trigger: textContentTitle,
          start: "top 75%",
          end: "bottom -20%",
          markers: false,
          toggleActions: "play stop play reverse",
        },
      })
      .addLabel("zero")
      .from(textContentTitleSplit.chars, commonEffects.titles, "zero")
      .addLabel("one")
      .to(textContentDescription, commonEffects.descriptions.to, "-=0.4")
      .addLabel("two")
      .to(teamSlider, commonEffects.slider.to, "-=1");
  },
};

module.exports = whoWeAreAnimations;
