import commonEffects from "./commonEffects";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { SplitText } from "gsap/SplitText";

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(SplitText);

const achievementsAnimations = {
  initLoad() {
    if ($(".achievements-selected-achievements").length == 0) return;
    this.intro();
  },
  intro() {
    // title
    const introTitle = $(
      ".achievements-selected-achievements__wrapper .page-title"
    );
    const introTitleSplit = new SplitText(introTitle, {
      type: "lines,words,chars",
      linesClass: "split-line",
    });

    const introSubtitle = $(
      ".achievements-selected-achievements__intro .title-wrapper .title "
    );
    const introSubtitleSplit = new SplitText(introSubtitle, {
      type: "lines,words,chars",
      linesClass: "split-line",
    });
    const introDescription = $(
      ".achievements-selected-achievements__intro .text-wrapper .description"
    );
    const selectedAchievementsSlider = $(".selected-achievement-wrapper ");

    gsap.set(introDescription, commonEffects.descriptions.set);
    gsap.set(selectedAchievementsSlider, commonEffects.slider.set);

    if ($(window).outerWidth() < 767) {
      gsap
        .timeline()
        .addLabel("zero")
        .from(introTitleSplit.chars, commonEffects.heroTitles, "zero")
        .addLabel("one")
        .from(introSubtitleSplit.chars, commonEffects.titles, "-=0.4")
        .to(introDescription, commonEffects.descriptions.to, "-=0.4");

      selectedAchievementsSlider.each(function (index, slide) {
        gsap
          .timeline({
            scrollTrigger: {
              trigger: slide,
              start: "top 170%",
              markers: false,
            },
          })
          .to(slide, {
            duration: 0.8,
            ease: "power4.out",
            yPercent: 0,
          });
      });

      return;
    }

    gsap
      .timeline()
      .addLabel("zero")
      .from(introTitleSplit.chars, commonEffects.heroTitles, "zero")
      .addLabel("one")
      .from(introSubtitleSplit.chars, commonEffects.titles, "-=0.4")
      .to(introDescription, commonEffects.descriptions.to, "-=0.4")
      .to(selectedAchievementsSlider, commonEffects.slider.to, "-=1")
  },
};

module.exports = achievementsAnimations;
