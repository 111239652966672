const voicesList = {
  initLoad() {
    this.servicesVoices();
  },
  servicesVoices() {

    if ($('.services-engineering').length == 0) return

    $('.services-engineering .c-two-columns__voices .voice__voice-wrapper').on('click', function () {

      $(this).toggleClass('opened');


    })

  }

}

module.exports = voicesList;
