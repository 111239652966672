const scrollMethods = {
  initLoad() {
    scrollMethods.bindScrollingLink();
  },
  bindScrollingLink() {
    $(document)
      .on('click', '.goToLink', function () {
        const target = $(this).data('target');
        const offset = $(this).data('offset') || -20;
        console.log(offset);
        if (typeof target !== 'undefined') {
          scrollMethods.goToPos(target, offset);
        }
      });
  },
  goToPos: (ref, offset = 0) => {
    const $ref = (typeof ref === 'string') ? $(ref) : ref;
    if ($(ref).length === 0) return false;
    const myPos = $ref.offset().top - offset;
    $('html,body').animate({
      scrollTop: myPos,
    },1000);
  },
}

export default scrollMethods
