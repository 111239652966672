import { gsap } from "gsap";

const menu = {
  initLoad() {
    menu.menuInit();
  },
  menuAnim() {
    gsap
      .timeline()
      .delay(0.5)
      .fromTo(
        ".header .logo-wrapper, .header .burger-wrapper",
        { opacity: 0 },
        { opacity: 1 }
      );
  },
  menuInit() {
    $(".burger-wrapper").on("click", () => {
      $(".header").toggleClass("opened");
      $("#layer").toggleClass("visible");

      // not clickable
      $(".burger-wrapper").addClass("no-events");

      // open menu and make burger clickable after transition
      // $(".header").toggleClass("opened");
      $(".header .menu-wrapper").toggleClass("opened");
      $(".header .menu-wrapper").toggleClass("is-hidden");

      // menu was opened
      if (!$(".header, .burger-wrapper").hasClass("opened")) {
        $(".burger-wrapper").removeClass("no-events");
        return;
      }

      gsap.set(".menu-wrapper ul li", {
        clipPath: "polygon(0% 0%, 0% 0%, 0% 100%, 0% 100%)",
      });
      gsap.set(".menu-wrapper ul li a", { yPercent: 200, opacity: 0 });
      gsap
        .timeline()
        .addLabel("zero")
        .to(
          ".menu-wrapper ul li",
          {
            ease: "power4.out",
            clipPath: "polygon(0 0, 100% 0, 100% 100%, 0% 100%)",
            stagger: 0.2,
          },
          "zero+=.55"
        )
        .to(
          ".menu-wrapper ul li a",
          {
            opacity: 1,
            yPercent: 0,
            stagger: 0.15,
          },
          "zero+=.6"
        )
        .fromTo(
          ".header .menu-wrapper__languages span",
          { opacity: 0 },
          { opacity: 1, stagger: 0.1 },
          "zero+=1.1"
        )
        .fromTo(
          ".header .menu-wrapper__languages li",
          { opacity: 0 },
          { opacity: 1, stagger: 0.1 },
          "zero+=1.2"
        );

      setTimeout(() => {
        $(".burger-wrapper").removeClass("no-events");
      }, 500);
    });
  },
  initScroll() {
    menu.menuSticky();
  },
  menuSticky() {
    const scroll = $(window).scrollTop();
    const initialPos =
      $(window).outerHeight() - ($(".header").outerHeight() + 30);
    if (scroll > initialPos) {
      $(".header").removeClass("is-transparent");
      $(".header").addClass("fixed");
      $(".header").addClass("dark");
    } else {
      $(".header").removeClass("fixed");

      this.setHeaderColor();

      // bg color
      if ($(window).scrollTop() == 0) {
        $(".header").removeAttr("style");
        $(".header").addClass("is-transparent");
      }
    }
  },
  setHeaderColor() {
    const triggerElements = [
      ".achievements-selected-achievements",
      ".selected-achievement-introduction",
      ".contact-us",
      ".certification-title",
    ];

    for (const element of triggerElements) {
      if ($(element).length > 0) {
        console.log("logo dark");
        return $(".header").addClass("dark");
      }
    }

    $(".header").removeClass("dark");
  },
};

module.exports = menu;
