import commonEffects from "./commonEffects";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { SplitText } from "gsap/SplitText";

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(SplitText);

const contactsAnimations = {
  initLoad() {
    if ($(".contact-us").length == 0) return;
    this.intro();
  },
  intro() {
    // title
    const introTitle = $(".contact-us__wrapper .page-title");
    const introTitleSplit = new SplitText(introTitle, {
      type: "lines,words,chars",
      linesClass: "split-line",
    });

    const introSubtitle = $(
      ".contact-us__wrapper-inner .c-text-content__title "
    );
    const introSubtitleSplit = new SplitText(introSubtitle, {
      type: "lines,words,chars",
      linesClass: "split-line",
    });
    const introDescription = $(
      ".contact-us__wrapper-inner .c-text-content__description"
    );

    gsap.set(introDescription, commonEffects.descriptions.set);

    gsap
      .timeline()
      .addLabel("zero")
      .from(introTitleSplit.chars, commonEffects.heroTitles, "zero")
      .addLabel("one")
      .from(introSubtitleSplit.chars, commonEffects.titles, "-=0.4")
      .to(introDescription, commonEffects.descriptions.to, "-=0.4");

  },
};

module.exports = contactsAnimations;
