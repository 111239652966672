import homeAnimations from "./pages/home";
import whoWeAreAnimations from "./pages/who-we-are";
import servicesAnimations from "./pages/services";
import methodAnimations from "./pages/method";
import achievementsAnimations from "./pages/achievements";
import singleAchievementAnimations from "./pages/single-achievement";
import contactsAnimations from "./pages/contacts";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
var Sticky = require("sticky-js");
import menu from "../menu";
import scrollMethods from "./scrollMethods";

gsap.registerPlugin(ScrollTrigger);

const animations = {
  canRunAnim: false,
  initLoad() {
    animations.fixLabels();
    animations.heroArrows();
    animations.formEnable();
    animations.gototop()

    // wait preloader
    let timeToWait = $("#preloader").length > 0 ? 1800 : 200;
    setTimeout(() => {
      animations.linkAncors();

      // avoid animations on mobile when clicking anchor URL
      if (animations.hasAnchor() && $(window).outerWidth() < 767) return;
      animations.importAnims();

      menu.menuAnim();
    }, timeToWait);
  },
  importAnims() {
    if ($(".home-hero").length > 0) homeAnimations.initLoad();
    if ($(".who-we-are-hero").length > 0) whoWeAreAnimations.initLoad();
    if ($(".services-hero").length > 0) servicesAnimations.initLoad();
    if ($(".method-hero").length > 0) methodAnimations.initLoad();
    if ($(".achievements-selected-achievements__wrapper").length > 0)
      achievementsAnimations.initLoad();
    if ($(".single-achievement-phases").length > 0)
      singleAchievementAnimations.initLoad();
    if ($(".contacts-where").length > 0) contactsAnimations.initLoad();
  },
  fixLabels() {
    if ($(window).outerWidth() < 991) return;
    console.log($(".header").outerHeight() + 30);
    var sticky = new Sticky(".js-sticky-widget", {
      marginTop: $(".header").outerHeight() + 30,
      wrap: true,
    });
  },
  formEnable() {
    $(".c-contact-form-wrapper .wpcf7-acceptance input").on(
      "click",
      function () {
        $(".c-contact-form-wrapper .submit-wrapper").toggleClass("enabled");
      }
    );
  },
  preloader() {
    if ($("#preloader").length == 0) return;

    const preloader = $("#preloader");
    const svgLOGO = $("#svgLogoLetter");
    const logoLetterCircle = $("#logoLetterCircle");
    // console.log("start");

    preloader.height($(window).outerHeight());
    gsap
      .timeline({
        onComplete: function () {
          preloader.remove();
          // console.log("removed");
        },
      })
      .to(logoLetterCircle, {
        duration: 1,
        transformOrigin: "center center",
        rotate: 360,
      })
      // .to(svgLOGO, { duration: 1, scale: 100 })
      .to(preloader, { yPercent: -100 }, "+=1");
  },
  detectHeroScroll() {
    if ($(window).outerWidth() < 991) return;
    if ($(".c-hero").length == 0) return;
    if ($(".contacts-hero").length > 0) return;

    let heroCanAnim = true;

    $(".c-hero__link").on("click", function () {
      heroCanAnim = false;
      animations.heroScroll();
    });

    $(".c-hero").on("wheel", function (e) {
      let isScrollingDown = e.originalEvent.wheelDelta < 0;
      if (heroCanAnim && isScrollingDown) {
        heroCanAnim = false;

        animations.heroScroll();
      }
    });
  },
  gototop(){

    $('#gototop').on('click',function(){


      $("html, body").animate(
        { scrollTop: 0 },
        1000,
        "swing"
      );

    })


  },
  heroScroll() {
    $("html, body").animate(
      { scrollTop: $(window).outerHeight() },
      1000,
      "swing",
      function () {
        $(".c-hero").addClass("has-scrolled");
      }
    );
  },

  heroArrows() {
    // arrow hover
    let heroLink = $(".c-hero .c-hero__link");
    let isAnim = false;
    heroLink.on("mouseover", function () {
      if (!isAnim) {
        isAnim = true;
        // console.log('enteer');
        $(this).addClass("moveCircle");
        $(this).removeClass("moveDown");
        setTimeout(() => {
          // console.log('can anim now');
          $(this).removeClass("moveCircle");
          $(this).addClass("moveDown");
          isAnim = false;
        }, 1250);
      }
    });
  },
  hasAnchor() {
    return window.location.hash.length > 0;
  },
  linkAncors() {
    if (animations.hasAnchor()) {
      let target = $(window.location.hash);
      let offset = $(".header").outerHeight();
      console.log(target);
      scrollMethods.goToPos(target, offset);
      ScrollTrigger.refresh();
    }
  },
  backgroundChange() {
    $("#main_cont").removeAttr("background-color");

    if ($(".background-will-change").length == 0) return;

    const pageContainer = "#main_cont";
    const header = ".header";
    const defaultPageColor = "#e4e4e4";

    const $duration = 1;
    const sections = $(".background-will-change");

    sections.each(function () {
      const $section = $(this);

      gsap
        .timeline({
          onStart: function () {
            console.log("start section " + $section.attr("class"));
          },
          scrollTrigger: {
            trigger: $section,
            start: "1% top",
            end: "+=300px",
            markers: false,
            scrub: true,
          },
        })
        .addLabel("zero")
        .to(
          pageContainer,
          {
            duration: $duration,
            backgroundColor: $section.data("background") || defaultPageColor,
          },
          "zero"
        )
        .to(
          header,
          {
            duration: $duration,
            backgroundColor: $section.data("background") || defaultPageColor,
          },
          "zero"
        );
    });
  },
};

module.exports = animations;
