const video = {
	initReady() {
		this.initVideo();
	},

	initVideo() {
		const $el = $('.c-hero__video');
		if($el.length) {
			const src = $el.data('src');
			$el.find('source').attr('src', src);
			$el.attr('preload', 'auto')
			$el[0].load()
			$el[0].play()
		}

	},
}

export default video;
