
const maps = {
  $wrap: $('body'),
  url: "https://goo.gl/maps/biHRwPGnHmrSUiyaA",
//   lat: 45.6466841,
//   lng: 12.4131657,
lat: 45.6481983,
lng:12.4153439,
  zoom: 17,
  // pathPrefix: (document.location.hostname === 'localhost' || document.location.hostname === 'html.spider4web.it') ? './' : '/',

  initLoad() {
      this.loadGmap();
  },


  loadGmap: () => {
    if ($('#map', maps.$wrap).length === 0) return false;
    if (typeof google === 'undefined') {
      $.getScript('https://maps.googleapis.com/maps/api/js?key=AIzaSyAhXgLaoaP7bNQHlXltlOZZ3D9PdPJ22e8', () => {
        maps.initMap();
      });
    } else {
      maps.initMap();
    }
  },

  initMap: () => {
    if ($('#map', maps.$wrap).length === 0) return false;

    const pos = { lat: maps.lat, lng: maps.lng };

    const map = new google.maps.Map($('#map', maps.$wrap)[0], {
      zoom: maps.zoom,
      center: pos,
      styles: [
        {
            "featureType": "water",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#e9e9e9"
                },
                {
                    "lightness": 17
                }
            ]
        },
        {
            "featureType": "landscape",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#999999"
                },
                {
                    "lightness": 20
                }
            ]
        },
        {
            "featureType": "road.highway",
            "elementType": "geometry.fill",
            "stylers": [
                {
                    "color": "#ffffff"
                },
                {
                    "lightness": 17
                }
            ]
        },
        {
            "featureType": "road.highway",
            "elementType": "geometry.stroke",
            "stylers": [
                {
                    "color": "#ffffff"
                },
                {
                    "lightness": 29
                },
                {
                    "weight": 0.2
                }
            ]
        },
        {
            "featureType": "road.arterial",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#ffffff"
                },
                {
                    "lightness": 18
                }
            ]
        },
        {
            "featureType": "road.local",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#ffffff"
                },
                {
                    "lightness": 16
                }
            ]
        },
        {
            "featureType": "poi",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#f5f5f5"
                },
                {
                    "lightness": 21
                }
            ]
        },
        {
            "featureType": "poi.park",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#dedede"
                },
                {
                    "lightness": 21
                }
            ]
        },
        {
            "elementType": "labels.text.stroke",
            "stylers": [
                {
                    "visibility": "on"
                },
                {
                    "color": "#ffffff"
                },
                {
                    "lightness": 16
                }
            ]
        },
        {
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "saturation": 36
                },
                {
                    "color": "#333333"
                },
                {
                    "lightness": 40
                }
            ]
        },
        {
            "elementType": "labels.icon",
            "stylers": [
                {
                    "visibility": "off"
                }
            ]
        },
        {
            "featureType": "transit",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#f2f2f2"
                },
                {
                    "lightness": 19
                }
            ]
        },
        {
            "featureType": "administrative",
            "elementType": "geometry.fill",
            "stylers": [
                {
                    "color": "#fefefe"
                },
                {
                    "lightness": 20
                }
            ]
        },
        {
            "featureType": "administrative",
            "elementType": "geometry.stroke",
            "stylers": [
                {
                    "color": "#fefefe"
                },
                {
                    "lightness": 17
                },
                {
                    "weight": 1.2
                }
            ]
        }
    ],
    });

    const opts = {
      anchor: new google.maps.Point(42.7, 122),
      // path: 'M42.7 0C19.1 0 0 19.1 0 42.7v.1c0 32.1 42.7 79.4 42.7 79.4s42.7-47.3 42.7-79.4C85.5 19.1 66.4 0 42.7 0c.1 0 .1 0 0 0zm0 58c-8.4 0-15.3-6.8-15.3-15.3 0-8.4 6.8-15.3 15.3-15.3 8.4 0 15.3 6.8 15.3 15.3C58 51.1 51.2 58 42.7 58z',
      fillColor: '#2d3447',
      fillOpacity: 1,
      strokeWeight: 0,
    };
    if ($(window).outerWidth() < 1024) {
      opts.scale = 0.5;
    }

    const marker = new google.maps.Marker({
      position: pos,
      // icon: opts,
      icon: '/images/svg/marker.svg',
      map,
      url: maps.url,
    });

    google.maps.event.addListener(marker, 'click', function () {
      window.open(this.url, '_blank');
    });
  },

};

module.exports = maps;
