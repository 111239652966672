import commonEffects from "./commonEffects";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { SplitText } from "gsap/SplitText";
import { servicesVoices } from "../voicesList";

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(SplitText);

const homeAnimations = {
  initLoad() {
    if ($(".home-hero").length == 0) return;

    this.hero();
    this.textContent();
    this.introduction();
    this.services();
    this.methods();
    this.achievements();
  },
  hero() {
    // title
    const heroTitle = $(".home-hero .c-hero__title");
    const heroTitleSplit = new SplitText(heroTitle, {
      type: "lines,words,chars",
      charsClass: "char",
      linesClass: "split-line",
    });

    const heroDescription = $(".home-hero .c-hero__description");
    const heroArrow = $(".home-hero .c-hero__link");

    gsap.set(heroArrow, { opacity: 0 });
    gsap.set(heroTitle, commonEffects.descriptions.set);
    gsap.set(heroDescription, commonEffects.descriptions.set);
    gsap
      .timeline()
      .delay(0.5)
      .addLabel("zero")
      .from(heroTitleSplit.chars, commonEffects.heroTitles, "zero")
      .to(heroTitle, { opacity: 1 }, "zero")
      .to(heroDescription, commonEffects.descriptions.to, "zero+=0.5")
      .to(
        heroArrow,
        {
          opacity: 1,
          transformOrigin: "center center",
          duration: 0.8,
          ease: "Power2.out",
        },
        "zero+=0.5"
      );
  },
  textContent() {
    // title
    const textContentTitle = $(".home-text-content .c-text-content__title");
    const textContentTitleSplit = new SplitText(textContentTitle, {
      type: "lines,words",
      linesClass: "split-line",
      wordsClass: "split-line__word",
    });

    const textContentDescription = $(
      ".home-text-content .c-text-content__description"
    );

    const single__SVGline = $(".home-text-content__line svg");

    let arrowAnimStarted = false;
    gsap.set(textContentDescription, commonEffects.descriptions.set);
    gsap
      .timeline({
        onStart: function () {
          const single__start = single__SVGline.find("#start");
          const single__end = single__SVGline.find("#end");
          const single__middle = single__SVGline.find("#middle");

          if (arrowAnimStarted) return;
          arrowAnimStarted = true;

          const time = 1.5;
          const ease = "none";
          const arrowTl = gsap.timeline({
            repeat: -1,
            repeatDelay: 1.5,
            yoyo: true,
          });
          arrowTl.addLabel("start");
          arrowTl.fromTo(
            single__end,
            {
              transform: "translateX(-75%)",
              "-webkit-transform": "translateX(-75%)",
            },
            {
              ease: ease,
              duration: time,
              transform: "translateX(0%)",
              "-webkit-transform": "translateX(0%)",
            },
            "start"
          );
          arrowTl.fromTo(
            single__middle,
            { clipPath: "polygon(0 0, 0 0, 0 100%, 0% 100%)" },
            {
              ease: ease,
              duration: time,
              clipPath: "polygon(100% 0, 0 0, 0 100%, 100% 100%)",
            },
            "start"
          );
          arrowTl.addLabel("middle");
          arrowTl.to(
            single__middle,
            {
              ease: ease,
              duration: time,
              clipPath: "polygon(100% 0, 100% 0%, 100% 100%, 100% 100%)",
            },
            "middle"
          );
          arrowTl.fromTo(
            single__start,
            {
              transform: "translateX(0%)",
              "-webkit-transform": "translateX(0%)",
            },
            {
              ease: ease,
              duration: time,
              transform: "translateX(75%)",
              "-webkit-transform": "translateX(75%)",
            },
            "middle"
          );
        },
        scrollTrigger: {
          trigger: textContentTitle,
          start: "center center",
          markers: false,
        },
      })
      .addLabel("zero")
      .from(textContentTitleSplit.words, commonEffects.titles, "zero-.3")
      .to(textContentDescription, commonEffects.descriptions.to, "zero+=.3");
  },

  introduction() {
    // title
    const introductionTitle = $(".home-introduction .c-text-content__title");
    const introductionTitleSplit = new SplitText(introductionTitle, {
      type: "lines,words,chars",
      linesClass: "split-line",
    });

    const introductionDescriptions = $(
      ".home-introduction .c-text-content__description"
    );
    const introductionDescription = introductionDescriptions.eq(0);
    const subtextDescription = introductionDescriptions.eq(1);

    const arrowWrapper = $(".home-introduction__arrow-wrapper");

    let arrowAnimStarted = false;
    gsap.set(introductionDescription, commonEffects.descriptions.set);
    gsap.set(subtextDescription, commonEffects.descriptions.set);
    gsap
      .timeline({
        onStart: function () {
          if (arrowAnimStarted) return;
          arrowAnimStarted = true;
          let time = 1;
          let arrowTl = gsap.timeline({
            repeat: -1,
            repeatDelay: 2,
          });
          arrowTl.addLabel("start");
          arrowTl.fromTo(
            arrowWrapper,
            {
              transform: "translateX(-100%)",
            },
            {
              ease: "circ.easeOut",
              duration: time,
              stagger: {
                amount: 0.6,
              },
              transform: "translateX(0%)",
            },
            "start"
          );
          arrowTl.to(
            arrowWrapper.get().reverse(),
            {
              ease: "circ.easeOut",
              duration: time,
              stagger: {
                amount: 0.6,
              },
              transform: "translateX(150%)",
            },
            "+=1"
          );
        },
        scrollTrigger: {
          trigger: introductionTitle,
          start: "top 85%",
          end: "+=140%",
          markers: false,
          toggleActions: "play reverse play reverse",
        },
      })
      .addLabel("zero")
      .from(introductionTitleSplit.chars, commonEffects.titles, "zero")
      .addLabel("one")
      .to(introductionDescription, commonEffects.descriptions.to, "+=.1")
      .addLabel("two")
      .to(subtextDescription, commonEffects.descriptions.to, "+=.1");
  },
  services() {
    // title
    const servicesTitle = $(".home-services .c-text-content__title");
    const servicesTitleSplit = new SplitText(servicesTitle, {
      type: "lines,words,chars",
      linesClass: "split-line",
    });

    const servicesLink = $(".home-services .c-text-content__link");
    const servicesArrowCircle = $(".home-services #border");
    const servicesArrowCircleInner = $(".home-services .inner-arrow");
    const servicesLabels = $(".home-services .list-wrapper .label");
    const servicesVoices = $(".home-services .list-wrapper__voice");

    gsap.set(servicesLink, commonEffects.descriptions.set);
    gsap.set(servicesLabels, commonEffects.lists.set);
    let arrowAnimStarted = false;
    gsap
      .timeline({
        onStart: function () {
          if (arrowAnimStarted) return;
          arrowAnimStarted = true;
          let time = 2;
          let arrowTl = gsap.timeline({
            repeat: -1,
            repeatDelay: 2,
          });
          arrowTl.addLabel("start");
          arrowTl.fromTo(
            servicesArrowCircle,
            {
              rotate: 0,
            },
            {
              duration: time,
              ease: "ease-in",
              transformOrigin: "50% 50%",
              rotate: 360,
            },
            "start"
          );
          arrowTl.fromTo(
            servicesArrowCircleInner,
            {
              rotate: 0,
            },
            {
              duration: time,
              ease: "ease-in",
              transformOrigin: "50% 50%",
              rotate: -360,
            },
            "start"
          );
        },
        scrollTrigger: {
          trigger: servicesTitle,
          start: "top bottom",
          end: "bottom -60%",
          markers: false,
          toggleActions: "play reverse play reverse",
        },
      })
      .addLabel("zero")
      .from(servicesTitleSplit.chars, commonEffects.titles, "zero")
      .addLabel("one")
      .to(servicesLink, commonEffects.descriptions.to, "+=.1")
      .addLabel("two")
      .fromTo(
        servicesVoices,
        { clipPath: "polygon(0 0, 0 0, 0 100%, 0% 100%)" },
        { stagger: 0.1, clipPath: "polygon(100% 0, 0 0, 0 100%, 100% 100%)" },
        "two-=1"
      )
      .to(servicesLabels, commonEffects.lists.to, "two-=.8");
  },
  methods() {
    // title
    const methodsTitle = $(".home-methods .c-text-content__title");
    const methodsTitleSplit = new SplitText(methodsTitle, {
      type: "lines,words,chars",
      linesClass: "split-line",
    });

    const methodsDescription = $(".home-methods .c-text-content__description");
    const methodsLink = $(".home-methods .c-text-content__link");

    gsap.set(methodsDescription, commonEffects.descriptions.set);
    gsap.set(methodsLink, commonEffects.descriptions.set);

    gsap
      .timeline({
        scrollTrigger: {
          trigger: methodsTitle,
          start: "top bottom",
          end: "bottom -60%",
          toggleActions: "play reverse play reverse",
        },
      })
      .addLabel("zero")
      .from(methodsTitleSplit.chars, commonEffects.titles, "zero")
      .addLabel("one")
      .to(methodsDescription, commonEffects.descriptions.to, "+=.1")
      .to(methodsLink, commonEffects.descriptions.to, "+=.1");

    const methodsVoices = $(".home-methods__list-group .list-wrapper__voice");
    const methodsVoicesWrapper = $(".home-methods__list-group .wrapper");

    gsap.set(methodsVoicesWrapper, commonEffects.lists.set);
    gsap
      .timeline({
        onComplete: function () {
          $(".home-methods .list-wrapper__voice").addClass("maskCanBeSeen");
          $(".home-methods .list-wrapper__voice.maskCanBeSeen").on(
            "mousemove",
            function (e) {
              const methodsImage = $(this).find(".js-hidden-image-wrapper");

              // relative to eleement pos.
              var relX = e.pageX - $(this).offset().left;
              var relY = e.pageY - $(this).offset().top;

              // we divide just to make movement less powerful
              gsap
                .timeline()
                .addLabel("start")
                .to(methodsImage, { top: relY / 2, x: relX / 5 }, "start");
            }
          );
        },
        scrollTrigger: {
          trigger: methodsVoicesWrapper,
          start: "top 120%",
          end: "bottom -60%",
          markers: false,
          toggleActions: "play reverse play reverse",
        },
      })
      .addLabel("start")
      .fromTo(
        methodsVoices,
        { clipPath: "polygon(0 0, 0 0, 0 100%, 0% 100%)" },
        { stagger: 0.1, clipPath: "polygon(100% 0, 0 0, 0 100%, 100% 100%)" },
        "start+=.5"
      )
      .to(methodsVoicesWrapper, commonEffects.lists.to, "start+=.7")
      .set(methodsVoices, { clipPath: "unset" });
  },
  achievements() {
    // title
    const achievementsTitle = $(".home-achievements .home-achievements__title");
    const achievementsTitleSplit = new SplitText(achievementsTitle, {
      type: "lines,words,chars",
      linesClass: "split-line",
    });

    const achievementsSlider = $(".home-achievements .slider-wrapper__slide");

    gsap.set(achievementsTitle.find("svg"), { opacity: 0 });
    gsap.set(achievementsSlider, commonEffects.slider.set);
    gsap
      .timeline({
        scrollTrigger: {
          trigger: achievementsTitle,
          start: "top bottom",
          end: "bottom -20%",
          markers: false,
          toggleActions: "play pause play pause",
        },
      })
      .addLabel("zero")
      .from(achievementsTitleSplit.chars, commonEffects.titles, "zero")
      .set(achievementsTitle.find("svg"), { opacity: 1 }, "zero")
      .to(achievementsSlider, commonEffects.slider.to, "zero+=.2");
  },
};

module.exports = homeAnimations;
