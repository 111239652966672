import "slick-carousel";
import Splide from "@splidejs/splide";

const slider = {
  initLoad() {
    slider.homeSlider();
    slider.sliderDescWidth()
    slider.phasesSlider();
  },
  homeSlider() {
    if ($(".home-achievements .slider-wrapper").length > 0) {
      $(".home-achievements .slider-wrapper").slick({
        slidesToShow: 2.5,
        variableWidth: false,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 5000,
        speed: 450,
        infinite: true,
        dots: true,
        // appendDots: ,
        prevArrow: '<span class="arrow-prev"></span>',
        nextArrow: '<span class="arrow-next"></span>',
        appendArrows: ".slider-arrows",
        swipe: true,
        responsive: [
          {
            breakpoint: 992,
            settings: {
              slidesToShow: 1,
              swipe: true,
            },
          },
        ],
      });
    }
  },
  sliderDescWidth() {
    if ($(".single-achievement-phases .splide").length == 0) return;
    // if ($(window).outerWidth() < 991) return;

    setTimeout(() => {
      $(".slider-wrapper__slide").each(function () {
        let slide = $(this);
        let image = $(this).find(".image");
        slide.find(".title, .description").css("max-width", image.outerWidth());
      });
    }, 1000);
  },
  // phasesSlider() {

  //   if ($(".single-achievement-phases .slider-wrapper").length > 0) {
  //     $(".single-achievement-phases .slider-wrapper").slick({
  //       slidesToShow: 3,
  //       respondTo: 'slider',
  //       slidesToScroll: 1,
  //       autoplay: false,
  //       autoplaySpeed: 5000,
  //       speed: 450,
  //       dots: true,
  //       // appendDots: ,
  //       prevArrow: '<span class="arrow-prev"></span>',
  //       nextArrow: '<span class="arrow-next"></span>',
  //       appendArrows: ".slider-arrows",
  //       swipe: true,
  //       adaptiveHeight: false,
  //       infinite: true,
  //       variableWidth: true,
  //       responsive: [
  //         {
  //           breakpoint: 992,
  //           settings: {
  //             adaptiveHeight: true,
  //             slidesToShow: 1,
  //             variableWidth: false,
  //           },
  //         },
  //       ],
  //     });

  //     // le slide appaiono leggermente traslate.
  //     // 1s dopo la creazione dello slider, vai alla slide 0
  //     // in modo da ricalcolare la posizione
  //     setTimeout(() => {
  //       $(".single-achievement-phases .slider-wrapper").slick('slickGoTo',0);
  //     }, 1000);
  //   }
  // },
  phasesSlider() {
    if ($(".single-achievement-phases .splide").length > 0) {
      var splide = new Splide(".splide", {
        fixedWidth: false,
        fixedHeight: true,
        autoWidth: true,
        gap: 30,
        pagination: true,
        breakpoints: {
          767: {
            // perPage: 1,
            // drag:'free'
            snap: true,
            // lazyLoad: 'nearby'
          },
        }
      });
      splide.mount();

      console.log("swiper");
    }
  },
};

module.exports = slider;
