import commonEffects from "./commonEffects";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { SplitText } from "gsap/SplitText";

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(SplitText);

const singleAchievementAnimations = {
  initLoad() {
    if ($(".single-achievement-phases").length == 0) return;
    this.intro();
    this.phases();
  },
  intro() {
    const sectionLabel = $(".section-label");
    const backTo = $(".back-to");

    // title
    const introTitle = $(".general-content-wrapper__data-title");
    const introTitleSplit = new SplitText(introTitle, {
      type: "lines,words,chars",
      linesClass: "split-line",
    });
    const introDesc = $(".general-content-wrapper__description");

    const introData = $(".secondary-data .data-row");
    const image = $(
      ".selected-achievement-introduction__wrapper-inner .image-wrapper"
    );

    gsap.set(introData, commonEffects.lists.set);
    gsap.set(introDesc, commonEffects.descriptions.set);
    gsap.set(image, commonEffects.images.set);
    gsap
      .timeline()
      .delay(0.5)
      .addLabel("zero")
      .fromTo(
        ".data-row-wrapper",
        { clipPath: "polygon(0 0, 0 0, 0 100%, 0% 100%)" },
        {
          stagger: 0.1,
          clipPath: "polygon(100% 0, 0 0, 0 100%, 100% 100%)",
        },
        "start"
      )
      .from(introTitleSplit.chars, commonEffects.heroTitles, "zero")
      .to(introData, commonEffects.lists.to, "zero+=.2")
      .to(image, commonEffects.images.to, "zero+=.4")
      .fromTo(
        [sectionLabel, backTo],
        { opacity: 0 },
        { stagger: 0.1, opacity: 1 },
        "zero+=.6"
      )
      .to(introDesc, commonEffects.descriptions.to, "zero+=.6");
  },
  phases() {
    // title
    const achievementsTitle = $(".single-achievement-phases__title");
    const achievementsTitleSplit = new SplitText(achievementsTitle, {
      type: "lines,words,chars",
      linesClass: "split-line",
    });

    const achievementsSlider = $(
      ".single-achievement-phases__wrapper .slider-wrapper .slick-slide"
    );

    gsap.set(achievementsSlider, commonEffects.slider.set);
    gsap
      .timeline({
        scrollTrigger: {
          trigger: achievementsTitle,
          start: "top bottom",
          // end: 'bottom -20%',
          markers: false,
          toggleActions: "play stop play stop",
        },
      })
      .addLabel("zero")
      .from(achievementsTitleSplit.chars, commonEffects.titles, "zero")
      .to(achievementsSlider, commonEffects.slider.to, "zero+=.25");
  },
};

module.exports = singleAchievementAnimations;
